import React from "react";
import {
  BadgeAssessmentState,
  BasicProfileFragment,
  DashboardProgramFragment,
  SectionItemStates,
} from "~/graphql/__generated";
import {
  Card,
  Progress,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Dialog,
  DialogTrigger,
  DialogContent,
} from "@mindstonehq/ui";
import programAvailableImg from "../../../public/assessment-available.png";
import programCompletedImg from "../../../public/launchpad-tick-confetti.png";
import leaderboardTrophyImg from "../../../public/leaderboard-trophy.svg";
import { Link } from "react-router";
import { CalendarIcon, Share2Icon } from "lucide-react";
import AddToCalendar from "~/components/add-to-calendar";
import { isFrontend } from "~/utils";
import { getOrdinalSuffix } from "~/utils/string-utils";
import { VideoCameraIcon as VideoCameraIconSolid } from "@heroicons/react/24/solid";
import { VideoCameraIcon } from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import AchievementCompleteDialog from "~/components/profile/achievement-complete-dialog";
import metrics from "~/utils/metrics";

enum ProgramProgress {
  NotStarted = "notStarted",
  InProgress = "inProgress",
  Completed = "completed",
}

export default function ProgramHome({
  spaceName,
  program,
  profile,
}: {
  spaceName: string | null | undefined;
  program: DashboardProgramFragment;
  profile: BasicProfileFragment | null | undefined;
}) {
  const cohortProgress = program.cohort?.progress || 0;
  const frontend = isFrontend();
  const assessment = program.cohort?.badges[0]?.assessment;
  const programProgress: ProgramProgress = React.useMemo(() => {
    var hasPersonalization = false;
    var personalizationDone = false;

    for (const section of program.cohort?.sections || []) {
      for (const item of section.items) {
        if (item.content?.__typename === "SectionItemContentPersonalizer") {
          hasPersonalization = true;
          if (item.state === SectionItemStates.Done) {
            personalizationDone = true;
          }
        }
      }
    }

    if (cohortProgress === 0) {
      //logic to not show the NotStarted state if the user has personalised (check FOX-519 for more context)
      if (!hasPersonalization || (hasPersonalization && !personalizationDone)) {
        return ProgramProgress.NotStarted;
      }
    }

    //logic to keep showing the NotStarted state until the user has completed the personalization
    if (hasPersonalization && !personalizationDone) {
      return ProgramProgress.NotStarted;
    }

    const assessmentState = assessment?.badgeAssessmentState;

    if (assessmentState === BadgeAssessmentState.Done) {
      return ProgramProgress.Completed;
    }

    return ProgramProgress.InProgress;
  }, [cohortProgress, program.cohort?.sections, program.cohort?.badges]);

  const getWelcomeDescription = () => {
    switch (programProgress) {
      case ProgramProgress.NotStarted:
        return "We'll walk you through every step, let's get started together!";
      case ProgramProgress.InProgress:
        return "You're making great progress. Keep the momentum up!";
      case ProgramProgress.Completed:
        return "Congratulations! You've successfully completed this program, what a great achievement!";
    }
  };

  const getProgramTitle = () => {
    switch (programProgress) {
      case ProgramProgress.NotStarted:
        return "Start Learning";
      case ProgramProgress.InProgress:
        return "Continue Learning";
      case ProgramProgress.Completed:
        return "Your Program";
    }
  };

  const getProgramDescription = () => {
    switch (programProgress) {
      case ProgramProgress.NotStarted:
        return "Ready to begin? Start personalizing your path to AI mastery. You've got this!";
      case ProgramProgress.InProgress:
        const nextItemTitle =
          program.cohort?.firstIncompleteItem?.metadata?.title;
        return !!nextItemTitle
          ? `Up Next: ${nextItemTitle}`
          : "Continue your journey";
      case ProgramProgress.Completed:
        if (program.metadata.isPearsonProgram) {
          return "You’re ready for your Pearson Certification";
        }
        return "You've completed the program! Share your success and continue building your AI skills.";
    }
  };

  const getButtonText = () => {
    switch (programProgress) {
      case ProgramProgress.NotStarted:
        return "Begin now";
      case ProgramProgress.InProgress:
        return "Resume program";
      case ProgramProgress.Completed:
        if (program.metadata.isPearsonProgram) {
          return "Take exam";
        }
        return "Share Badge";
    }
  };

  const getProgramImage = () => {
    return programProgress === ProgramProgress.Completed
      ? programCompletedImg
      : programAvailableImg;
  };

  const nextLiveSession = program.cohort?.events?.find((event) => {
    const endTime = new Date(event.endDatetime);
    const endTimeWith30MinBuffer = new Date(endTime.getTime() + 30 * 60 * 1000);
    return new Date(endTimeWith30MinBuffer) > new Date();
  });

  const allSessionsComplete =
    (program.cohort?.events?.length || 0) > 0 && !nextLiveSession;

  const showLiveSessionCard = nextLiveSession || allSessionsComplete;

  const getLiveSessionCardTitle = () => {
    return nextLiveSession ? "NEXT LIVE SESSION" : "LIVE SESSIONS";
  };

  const getLiveSessionCardHeading = () => {
    if (nextLiveSession && frontend) {
      const timeZone = moment.tz.guess();
      const sessionDate = moment(nextLiveSession.startDatetime).tz(timeZone);
      return sessionDate.format("dddd D MMM, h:mma z");
    }
    return "All live sessions are now complete";
  };

  const hasRecordings = program.cohort?.events?.some((event) =>
    event.agenda?.some((item) => !!item.recording?.id),
  );

  const getLiveSessionCardSubtitle = () => {
    if (nextLiveSession) {
      return nextLiveSession.name;
    }

    return hasRecordings
      ? "Explore recordings for deeper insights anytime you wish."
      : "Explore previous content for deeper insights anytime you wish.";
  };

  const getLiveSessionCardButtons = () => {
    if (nextLiveSession) {
      return (
        <>
          <AddToCalendar
            events={[
              {
                id: nextLiveSession.id,
                name: nextLiveSession.name,
                startDatetime: nextLiveSession.startDatetime,
                endDatetime: nextLiveSession.endDatetime,
                description: nextLiveSession.eventDescription || "",
                joinUrl:
                  nextLiveSession.location?.__typename === "OnlineLocation"
                    ? nextLiveSession.location.joinUrl
                    : undefined,
                publicUrl:
                  nextLiveSession.location?.__typename === "OnlineLocation"
                    ? nextLiveSession.location.joinUrl
                    : undefined,
                agenda: [],
              },
            ]}
            downloadFilename={nextLiveSession.name}
            variant="outline"
            size="sm"
            className="flex-1"
            track={() => {
              metrics.track("live_session_calendar", {
                page: "home",
                programID: program.id,
                cohortID: program.cohort?.id || "",
                action: "add_live_session",
              });
            }}
          >
            <CalendarIcon className="w-4 h-4 mr-2" />
            <span className="md:hidden">Add</span>
            <span className="hidden md:inline">Add to calendar</span>
          </AddToCalendar>
          {nextLiveSession.location?.__typename === "OnlineLocation" && (
            <a href={nextLiveSession.location.joinUrl} className="flex-1">
              <Button variant="outline" size="sm" className="w-full">
                <VideoCameraIcon className="w-4 h-4 mr-2" />
                Join
              </Button>
            </a>
          )}
        </>
      );
    }

    if (hasRecordings) {
      return (
        <Link
          to={`/programs/${program.metadata.slug}#live-sessions`}
          className="w-full"
        >
          <Button variant="outline" size="sm" className="w-full">
            <VideoCameraIconSolid className="w-4 h-4 mr-2" />
            View recordings
          </Button>
        </Link>
      );
    }

    return (
      <Link
        to={`/programs/${program.metadata.slug}#overview`}
        className="w-full"
      >
        <Button variant="outline" size="sm" className="w-full">
          Open program overview
        </Button>
      </Link>
    );
  };

  const leaderboard = program.cohort?.leaderboard;
  const userHasProgress =
    (program.cohort?.badges[0]?.progress?.current || 0) > 0;

  const isProgramStarted = program.cohort?.metadata.startDate
    ? new Date(program.cohort.metadata.startDate) <= new Date()
    : false;

  const getLeaderboardTitle = () => {
    if (!isProgramStarted) {
      return "Coming Soon";
    }

    if (!userHasProgress) {
      return "Reveal where you stand";
    }

    if (leaderboard && !!leaderboard?.myRank) {
      const rank = leaderboard.myRank;
      const ordinalRank = `${rank}${getOrdinalSuffix(rank)}`;
      return (
        <>
          <span className="inline">
            You're ranked {ordinalRank}
            <span className="hidden sm:inline text-placeholder-foreground text-sm font-medium ml-1 leading-tight">
              out of {leaderboard.total}
            </span>
          </span>
        </>
      );
    }

    return "Leaderboard";
  };

  const getLeaderboardDescription = () => {
    if (!isProgramStarted) {
      return "Will activate once program kicks off";
    }

    return userHasProgress
      ? "Keep earning points to boost your rank!"
      : "Start learning to boost your rank!";
  };

  const getGreeting = (name?: string) => {
    if (frontend) {
      const now = new Date();
      const hour = now.getHours();
      const minutes = now.getMinutes();

      if (hour === 4) return "Hello night owl 🦉"; // Easter egg at 4am

      const getTimeBasedGreeting = (firstHalf: string, secondHalf: string) => {
        return minutes < 30 ? firstHalf : secondHalf;
      };

      if (hour < 12) {
        return getTimeBasedGreeting(
          `Good morning${name ? `, ${name}` : ""}!`,
          `Hey${name ? ` ${name}` : ""}!`,
        );
      }
      if (hour < 18) {
        return getTimeBasedGreeting(
          `Good afternoon${name ? `, ${name}` : ""}!`,
          `Welcome${name ? `, ${name}` : ""}!`,
        );
      }
      if (hour < 22) {
        return getTimeBasedGreeting(
          `Good evening${name ? `, ${name}` : ""}!`,
          `Hello${name ? ` ${name}` : ""}!`,
        );
      }
    }
    return `Hey${name ? ` ${name}` : ""}!`;
  };

  const getLinkDestination = () => {
    switch (programProgress) {
      case ProgramProgress.NotStarted:
      case ProgramProgress.InProgress:
        return ``;
      case ProgramProgress.Completed:
        return `#share-badge`;
    }
  };

  const badge = program.cohort?.badges[0];
  const assessmentComplete =
    assessment?.badgeAssessmentState === BadgeAssessmentState.Done;

  return (
    <div className="col-span-12 flex flex-col w-full">
      <div className="bg-indigo-50 dark:bg-slate-700 w-full">
        <div className="max-w-[1440px] mx-auto px-8 pt-5 pb-5 lg:pt-8 lg:pb-8">
          {/* Welcome section */}
          <section className="mb-6 flex items-center">
            <Avatar className="w-12 h-12 mr-4">
              <AvatarImage src={profile?.profilePictureURL} />
              <AvatarFallback>
                {profile?.displayName?.substring(0, 2)}
              </AvatarFallback>
            </Avatar>
            <div>
              <div className="text-foreground text-lg font-semibold leading-7">
                {getGreeting(
                  profile?.firstName || profile?.displayName || undefined,
                )}
              </div>
              <div className="text-foreground text-sm font-normal leading-tight">
                {getWelcomeDescription()}
              </div>
            </div>
          </section>

          {/* Program card section */}
          <section className="mt-8 space-y-2">
            <div className="text-foreground text-xl font-semibold leading-7">
              {getProgramTitle()}
            </div>
            <Card className="p-6">
              <div className="flex flex-col sm:flex-row items-center sm:items-stretch justify-between">
                <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-3 flex-grow w-full sm:w-auto">
                  <img
                    src={getProgramImage()}
                    alt={
                      programProgress === ProgramProgress.Completed
                        ? "Program completed"
                        : "Program available"
                    }
                    className="h-auto w-[100px] object-contain"
                  />
                  <div className="flex flex-col justify-center flex-grow w-full sm:w-auto">
                    <div>
                      <h3 className="text-xl font-semibold">
                        {program.metadata.title}
                      </h3>
                      <p className="text-placeholder-foreground text-sm">
                        {getProgramDescription()}
                      </p>
                    </div>
                    <div className="mt-4 w-full">
                      <div className="flex items-center justify-between max-w-[420px]">
                        <Progress
                          value={assessmentComplete ? 100 : cohortProgress}
                          className="h-1 bg-slate-200 flex-grow mr-2"
                        />
                        <p className="text-xs text-placeholder-foreground whitespace-nowrap">
                          {assessmentComplete ? 100 : cohortProgress}% complete
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-4 sm:mt-0 sm:ml-4 w-full sm:w-auto">
                  {programProgress === ProgramProgress.Completed ? (
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button size="lg" className="w-full sm:w-auto">
                          <Share2Icon className="h-4 w-4 mr-2" />
                          <div>Share Badge</div>
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <AchievementCompleteDialog
                          id={badge?.metadata?.id || ""}
                          name={badge?.metadata?.name || ""}
                          imageURL={badge?.metadata?.imageURL || ""}
                          shareURL={badge?.assessment?.shareURL || ""}
                          awardedOn={badge?.assessment?.awardedOn ?? undefined}
                          grantId={badge?.assessment?.grantId ?? undefined}
                        />
                      </DialogContent>
                    </Dialog>
                  ) : (
                    <Link
                      to={`/programs/${program.metadata.slug}${getLinkDestination()}`}
                      className="w-full sm:w-auto"
                    >
                      <Button className="w-full sm:w-auto px-8 h-11">
                        {getButtonText()}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </Card>
          </section>
        </div>
      </div>

      <div className="w-full">
        <div className="max-w-[1440px] mx-auto px-8 pt-5 pb-5 lg:pt-8 lg:pb-8">
          <div className="flex flex-col sm:flex-row gap-6">
            {/* Live session card section */}
            {showLiveSessionCard && (
              <section className="flex-1">
                <Card className="p-6 min-h-[170px] xl:h-[232px] flex flex-col justify-between">
                  <div>
                    <div className="text-muted-foreground text-sm font-semibold leading-tight mb-4">
                      {getLiveSessionCardTitle()}
                    </div>
                    <div className="text-foreground text-lg font-semibold leading-7">
                      {getLiveSessionCardHeading()}
                    </div>
                    <div className="text-placeholder-foreground text-sm font-normal leading-tight">
                      {getLiveSessionCardSubtitle()}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 mt-4">
                    {getLiveSessionCardButtons()}
                  </div>
                </Card>
              </section>
            )}

            {/* Leaderboard card section */}
            <section className="flex-1">
              <Card className="p-6 min-h-[170px] xl:h-[232px] flex flex-col justify-between">
                <div className="flex justify-between items-stretch h-full">
                  <div className="flex flex-col justify-between">
                    <div>
                      <div className="text-muted-foreground text-sm font-semibold leading-tight mb-4">
                        LEADERBOARD
                      </div>
                      <div className="text-foreground text-lg font-semibold leading-7">
                        {getLeaderboardTitle()}
                      </div>
                      <div className="text-placeholder-foreground text-sm font-normal leading-tight">
                        {getLeaderboardDescription()}
                      </div>
                    </div>
                    <div className="mt-4">
                      <Link
                        to={`/programs/${program.metadata.slug}#leaderboard`}
                        className="w-full sm:w-auto"
                      >
                        <Button variant="outline" size="sm">
                          View Leaderboard
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <img
                      src={leaderboardTrophyImg}
                      alt="Leaderboard Trophy"
                      className="h-auto w-[100px] object-contain"
                    />
                  </div>
                </div>
              </Card>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
