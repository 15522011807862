import { ClockIcon, DocumentTextIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router";
import copyToClipboard from "copy-to-clipboard";
import React from "react";
import { AchievementFragment } from "~/graphql/__generated";
import { classNames, converToHoursAndMinutes, formatDate } from "~/utils";
import { useUser } from "~/hooks/context-hook";
import { Button, DialogClose, DialogFooter, toast } from "@mindstonehq/ui";
import ProgressCircle from "~/components/progress-circle";
import { AnimatedCounter } from "~/components/animate-counter";

export default function AchievementDialog({
  achievement,
  justCompleted,
}: {
  achievement: AchievementFragment;
  justCompleted?: boolean;
}) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [explanationExpanded, setExplanationExpanded] = React.useState(false);
  const earnedEP = achievement.objective?.myLearningStats?.totals.earnedEP || 0;
  const percent =
    achievement.objective?.myLearningStats?.totals.progress?.percent || 0;
  const achieved = achievement.progress >= achievement.target;
  const me = useUser();

  React.useEffect(() => {
    if (containerRef.current) {
      var linesToRender = containerRef.current.getClientRects();
      if (linesToRender.length < 3) {
        setExplanationExpanded(true);
      }
    }
  }, []);

  const handleShareProfile = async () => {
    const notify = () => {
      toast.success("Profile link copied to clipboard");
    };
    const profileUrl = location.origin + `/profile/${me?.username}`;
    const shareData = {
      title: `${me?.username}'s profile on Mindstone`,
      text: `Get to know @${me?.username}'s achievements and skills`,
      url: profileUrl,
    };
    if (navigator.canShare && navigator.canShare(shareData)) {
      await navigator.share(shareData).catch((err) => {
        console.error(err);
        copyToClipboard(profileUrl);
        notify();
      });
    } else {
      copyToClipboard(profileUrl);
      notify();
    }
  };
  const panels = [
    {
      icon: (
        <div className="flex flex-col rounded-full h-8 w-8 flex items-center justify-center">
          <img
            src="/bolt-gradient.svg"
            alt="points"
            className="h-5 w-5 text-theme-text-inverted"
          />
        </div>
      ),
      title: `${earnedEP} EP`,
      subtitle: "earned",
    },
    {
      icon: (
        <div className="flex flex-col rounded-full h-8 w-8 bg-theme-background-sand-secondary-pressed flex items-center justify-center">
          <ClockIcon className="h-5 w-5 text-theme-text-inverted" />
        </div>
      ),
      title: converToHoursAndMinutes(
        achievement.objective?.myLearningStats?.totals.timeSpent || 0,
      ),
      subtitle: "time spent",
    },
    {
      icon: (
        <div className="flex flex-col rounded-full h-8 w-8 bg-theme-primary-green-border-dark flex items-center justify-center">
          <DocumentTextIcon className="h-5 w-5 text-theme-text-inverted" />
        </div>
      ),
      title: achievement.objective?.myLearningStats?.totals.resources || 0,
      subtitle: "resources done",
    },
  ];

  return (
    <>
      <div className="flex flex-col items-center py-6">
        <ProgressCircle size={156} completion={percent}>
          <div className="t-body-sm-bold bg-theme-progress-9 text-theme-text-inverted py-1 px-2 rounded-[100px] mb-3">
            {Math.round(percent)}%
          </div>
          <h2>
            <AnimatedCounter
              from={0}
              to={Math.round(earnedEP)}
              duration={1.5}
            />
          </h2>
          <div className="t-body-xs-regular text-tertiary">TOTAL POINTS</div>
        </ProgressCircle>
        {(achievement.objective?.explanation ||
          achievement.objective?.name) && (
          <div className="bg-muted text-muted-foreground rounded-xl p-6 flex flex-col mt-3 w-full">
            {achievement.objective?.name && (
              <div className="font-bold mb-1">{achievement.objective.name}</div>
            )}
            {achievement.objective?.explanation && (
              <>
                <div
                  className={classNames(
                    "text-base transition-all w-full",
                    explanationExpanded
                      ? "max-h-[400px]"
                      : "line-clamp-2 max-h-[50px]",
                  )}
                >
                  {achievement.objective?.explanation}
                </div>
                <div className="h-0 overflow-hidden">
                  <div ref={containerRef} className="inline">
                    {achievement.objective?.explanation}
                  </div>
                </div>
                {!explanationExpanded && (
                  <button
                    onClick={() => setExplanationExpanded(true)}
                    className="link"
                  >
                    see more
                  </button>
                )}
              </>
            )}
          </div>
        )}
        <div className="bg-muted text-muted-foreground rounded-xl p-6 flex items-center justify-between w-full mt-3">
          <div className="flex flex-col">
            {achieved ? (
              <>
                <div className="font-semibold">You smashed this!</div>
                <div className="text-sm">
                  You mastered this learning goal{" "}
                  <span>
                    {achievement.awardedOn ? (
                      <>
                        on {formatDate(new Date(achievement.awardedOn || ""))}
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
              </>
            ) : earnedEP > 0 ? (
              <>
                <div className="font-semibold">Keep going!</div>
                <div className="text-sm">
                  Master this learning goal to unlock this badge.
                </div>
              </>
            ) : (
              <>
                <div className="font-semibold">Get Started!</div>
                <div className="text-sm">
                  Master this learning goal to unlock this badge.
                </div>
              </>
            )}
          </div>
          <img className="ml-4 h-10 w-10" src={achievement.iconURL} />
        </div>

        {achieved && (
          <div className="flex space-x-3 h-[126px] mt-3">
            {panels.map((panel) => (
              <div
                key={panel.title}
                className="bg-theme-background-grey-primary-hover rounded-xl flex flex-col items-center justify-center w-[110px] space-y-[10px] py-2"
              >
                {panel.icon}
                <div className="flex flex-col items-center">
                  <h6>{panel.title}</h6>
                  <div className="t-body-sm-regular text-theme-text-secondary">
                    {panel.subtitle}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button variant="outline">Close</Button>
        </DialogClose>
        {achieved ? (
          <Button onClick={handleShareProfile}>Share Profile</Button>
        ) : (
          <Button asChild>
            <Link to="/">{earnedEP > 0 ? "Continue" : "Get Started"}</Link>
          </Button>
        )}
      </DialogFooter>
    </>
  );
}
