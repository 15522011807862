import PageContainer from "~/components/containers/page-container";
import { data, LoaderFunctionArgs } from "react-router";
import React, { Suspense } from "react";
import { GenericBoundary } from "~/components/error-boundaries/error-boundaries";
import ensureAuthenticated, {
  getAccessToken,
} from "~/utils/ensure-authenticated.server";
import { getServerClient } from "~/graphql";
import {
  LaunchpadDocument,
  LaunchpadQuery,
  LaunchpadQueryVariables,
} from "~/graphql/__generated";
import {
  Await,
  MetaFunction,
  useLoaderData,
  useRevalidator,
} from "react-router";
import { classNames } from "~/utils";
import LaunchpadPointsBreakdown from "~/routes/_app._index/launchpad-points-breakdown";
import LaunchpadProfile from "~/routes/_app._index/launchpad-profile";
import { Skeleton, Card } from "@mindstonehq/ui";
import { currentSpaceUniqueName } from "~/hooks/check-authenticated";
import usePageView from "~/hooks/page-view";
import ProgramHome from "./program-home";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) {
    return [{ title: " Mindstone" }];
  }
  return [
    {
      title: `Mindstone`,
    },
    {
      name: "description",
      content: `Set goals, track your progress, showcase your skills, learn faster & remember more.`,
    },
  ];
};
export const ErrorBoundary = GenericBoundary;

export async function loader({ request, params }: LoaderFunctionArgs) {
  ensureAuthenticated(request);
  const accessToken = await getAccessToken(request);
  const url = new URL(request.url);
  const promise = getServerClient()
    .query<LaunchpadQuery, LaunchpadQueryVariables>({
      query: LaunchpadDocument,
      variables: {
        uniqueName: currentSpaceUniqueName(request),
      },
      fetchPolicy: "no-cache",
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    })
    .catch((error) => {
      throw error;
    });

  return { promise };
}

export default function Index() {
  const loaderData = useLoaderData<typeof loader>();

  return (
    <PageContainer title={"Home"} noMargin>
      <Suspense fallback={<HomeSkeleton />}>
        <Await resolve={loaderData.promise}>
          {(result) => <Home data={result.data} />}
        </Await>
      </Suspense>
    </PageContainer>
  );
}

function HomeSkeleton() {
  return (
    <div className="col-span-12 flex flex-col w-full max-w-[1440px] mx-auto px-8 py-5 lg:py-8">
      {/* NOTE: We generated this skeleton based on the program-home layout using Claude. */}
      {/* Welcome section skeleton */}
      <section className="mb-6 flex items-center">
        <Skeleton className="w-12 h-12 rounded-full mr-4" />
        <div>
          <Skeleton className="w-48 h-7 mb-1" />
          <Skeleton className="w-64 h-5" />
        </div>
      </section>

      {/* Program card section skeleton */}
      <Card className="p-6 mb-6">
        <div className="flex flex-col sm:flex-row items-center sm:items-stretch justify-between">
          <div className="flex flex-col sm:flex-row items-center sm:items-stretch space-y-4 sm:space-y-0 sm:space-x-3 flex-grow w-full sm:w-auto">
            <Skeleton className="h-[100px] w-[100px]" />
            <div className="flex flex-col justify-center flex-grow w-full sm:w-auto">
              <Skeleton className="w-48 h-7 mb-2" />
              <Skeleton className="w-64 h-5 mb-4" />
              <Skeleton className="w-full h-1 max-w-[420px]" />
            </div>
          </div>
          <div className="flex items-center mt-4 sm:mt-0 sm:ml-4 w-full sm:w-auto">
            <Skeleton className="w-32 h-10" />
          </div>
        </div>
      </Card>

      {/* Additional cards section skeleton */}
      <div className="flex flex-col sm:flex-row gap-6">
        {/* Card 1 */}
        <Card className="flex-1 p-6 min-h-[170px] xl:h-[232px]">
          <div className="flex flex-col h-full justify-between">
            <div>
              <Skeleton className="w-32 h-5 mb-4" />
              <Skeleton className="w-48 h-7 mb-2" />
              <Skeleton className="w-64 h-5" />
            </div>
            <div className="flex flex-row gap-2 mt-4">
              <Skeleton className="w-32 h-9" />
              <Skeleton className="w-32 h-9" />
            </div>
          </div>
        </Card>

        {/* Card 2 */}
        <Card className="flex-1 p-6 min-h-[170px] xl:h-[232px]">
          <div className="flex justify-between items-stretch h-full">
            <div className="flex flex-col justify-between">
              <div>
                <Skeleton className="w-32 h-5 mb-4" />
                <Skeleton className="w-48 h-7 mb-2" />
                <Skeleton className="w-64 h-5" />
              </div>
              <div className="mt-4">
                <Skeleton className="w-40 h-9" />
              </div>
            </div>
            <div className="flex items-center justify-center ml-4">
              <Skeleton className="h-[100px] w-[100px]" />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

function Home({ data }: { data: LaunchpadQuery }) {
  usePageView("Home");
  const totalPoints = (
    data.myProfile?.evidencing?.topNSkillGroups || []
  ).reduce((acc, cur) => acc + cur?.points, 0);

  const inProgram = !!data?.mindspace.programs?.enrolled.items.length;
  const program = inProgram
    ? data?.mindspace.programs?.enrolled.items[0]
    : null;

  return (
    <>
      {inProgram && !!program ? (
        <ProgramHome
          spaceName={data?.mindspace.uniqueName}
          program={program}
          profile={data.myProfile}
        />
      ) : (
        <>
          <div
            className={classNames(
              "hidden lg:flex flex-col lg:min-h-0 space-y-8 col-span-6 p-4 sm:p-6",
            )}
          >
            <LaunchpadPointsBreakdown
              skills={data.myProfile?.evidencing?.topNSkillGroups || []}
              totalPoints={totalPoints}
            />
          </div>
          <div
            className={classNames(
              "col-span-12 flex flex-col space-y-6 lg:space-y-0 pb-6 lg:pb-0 lg:col-span-6 p-4 sm:p-6",
            )}
          >
            {data.myProfile && (
              <LaunchpadProfile
                profile={data.myProfile}
                totalEP={Math.round(totalPoints)}
                totalTimeLearning={data.myProfile?.stats?.minutes || 0}
              />
            )}
            <div className="flex lg:hidden flex-col">
              <LaunchpadPointsBreakdown
                skills={data.myProfile?.evidencing?.topNSkillGroups || []}
                totalPoints={totalPoints}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
