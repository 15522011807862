import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Card,
  Separator,
} from "@mindstonehq/ui";

import { Link } from "react-router";
import ProfileAchievements from "~/components/profile/profile-achievements";
import ProfileQRCode from "~/components/profile/profile-qr-code-dalog";
import { ProfileLevel } from "~/graphql/__generated";

export default function LaunchpadProfile({
  profile,
  totalEP,
  totalTimeLearning,
}: {
  profile: {
    profilePictureURL: string;
    displayName: string;
    tagLine?: string | null;
    achievements: {
      achievements: any[];
    };
    followers: {
      total: number;
    };
    followings: {
      total: number;
    };
    mine: boolean;
    username: string;
    level?: Array<{
      label: string;
    } | null> | null;
  };
  totalEP: number;
  totalTimeLearning: number;
}) {
  const levelData = profile?.level ? (profile.level[0] as ProfileLevel) : null;

  return (
    <Card className="p-6 flex flex-col items-center space-y-4 h-full">
      {profile.mine && (
        <div className="w-full flex sm:hidden justify-end">
          <ProfileQRCode profile={profile} />
        </div>
      )}
      <Avatar className="h-16 w-16">
        <AvatarImage src={profile.profilePictureURL} />
        <AvatarFallback>{profile.displayName.substring(0, 2)}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col items-center">
        <div className="text-lg text-center font-semibold leading-7">
          {profile.displayName}
        </div>
        {profile.tagLine && (
          <div className="text-sm text-center font-normal leading-none text-placeholder-foreground">
            {profile.tagLine}
          </div>
        )}
      </div>

      <div className="flex items-center justify-center py-2 px-4 space-x-4 bg-muted bg-opacity-50 rounded-md">
        <Link
          className="text-xs leading-tight text-center"
          to={`/profile/${profile.username}/followers`}
        >
          <strong>{profile.followers.total}</strong> Followers
        </Link>
        <Separator orientation="vertical" />
        <Link
          className="text-xs leading-tight text-center"
          to={`/profile/${profile.username}/following`}
        >
          <strong>{profile.followings.total}</strong> Following
        </Link>
      </div>
      {profile.achievements?.achievements.length > 0 && (
        <div className="w-full">
          <div className="my-4 w-full">
            <ProfileAchievements
              achievements={profile.achievements?.achievements}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col items-center bg-muted bg-opacity-50 rounded-md py-4 px-6 space-y-3 w-full">
        <div className="flex flex-col items-center">
          <div className="text-lg font-semibold leading-7">
            {levelData?.label}
          </div>
          <div className="text-xs font-medium leading-tight text-muted-foreground">
            Prompt Level
          </div>
        </div>
        <Separator />
        <div className="flex flex-col items-center">
          <div className="text-lg font-semibold leading-7">{totalEP}</div>
          <div className="text-xs font-medium leading-tight text-muted-foreground">
            Total Evidence Points earned
          </div>
        </div>
        <Separator />
        <div className="flex flex-col items-center">
          <div className="text-lg font-semibold leading-7">
            {totalTimeLearning}
          </div>
          <div className="text-xs font-medium leading-tight text-muted-foreground">
            Total learning time
          </div>
        </div>
      </div>
    </Card>
  );
}
