// export * from "./drop-types";
export * from "./classNames";
export * from "./is-frontend";
export * from "./date-format";
// export * from "./device-size";
// export * from "./url-validator";
// export * from "./debounce-function";
// export * from "./app-routes";
// export * from "./date-format";
// export * from "./device-check";
// export * from "./extension-version";
// export * from "./files";
// export * from "./menu-actions";
// export * from "./open-auth-window";
// export * from "./resource-icon";

export const converToHoursAndMinutes = (minutes: number) => {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return `${hours}h${minutesLeft}min`;
  }
  return `${minutes}min`;
};

export const checkAboveBreakpoint = (bp: string) => {
  if (typeof window === "undefined") {
    return true;
  }
  const width = window?.innerWidth;
  switch (bp) {
    case "2xl":
      return width >= 1536;

    case "xl":
      return width >= 1280;

    case "lg":
      return width >= 1024;

    case "md":
      return width >= 768;

    case "sm":
      return width >= 640;

    default:
      return false;
  }
};
