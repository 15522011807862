import React, { useState } from "react";
import { AchievementFragment } from "~/graphql/__generated";
// import AchievementDialog from "../app/dialogs/achievement-dialog";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@mindstonehq/ui";
import AchievementCompleteDialog from "~/components/profile/achievement-complete-dialog";
import AchievementDialog from "~/components/profile/achievement-dialog";

export default function ProfileAchievements({
  achievements,
}: {
  achievements: AchievementFragment[];
}) {
  const [selectedAchievement, setSelectedAchievement] =
    React.useState<AchievementFragment | null>(null);

  return (
    <div className="flex flex-col items-center space-y-1.5">
      <div className="flex items-center justify-center w-full space-x-4">
        {achievements
          .filter((ach) => !!ach.iconURL)
          .map((ach, i) => {
            const [open, setOpen] = useState(false);
            return (
              <Dialog
                open={open}
                onOpenChange={setOpen}
                key={i + " " + ach.objective?.id}
              >
                <DialogTrigger asChild>
                  <div>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button key={i}>
                          <img
                            key={ach.iconURL + i}
                            src={ach.iconURL}
                            className={"h-8 cursor-pointer hover:scale-105"}
                          />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <span>{ach.objective?.name}</span>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                </DialogTrigger>
                <DialogContent>
                  {open &&
                    (ach.awardedOn && ach.shareURL ? (
                      <AchievementCompleteDialog
                        id={ach.achievementID}
                        name={ach.objective?.name || ""}
                        imageURL={ach.iconURL}
                        awardedOn={ach.awardedOn}
                        shareURL={ach.shareURL}
                        grantId={ach.id}
                      />
                    ) : (
                      <AchievementDialog achievement={ach} />
                    ))}
                </DialogContent>
              </Dialog>
            );
          })}
      </div>
      <div className="text-xs font-medium leading-tight">Earned badges</div>
    </div>
  );
}
