import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mindstonehq/ui";
import { CubeIcon } from "@heroicons/react/24/outline";

export default function LaunchpadPointsBreakdown({
  skills,
  totalPoints,
}: {
  skills: { skillGroup: { name: string }; points: number }[];
  totalPoints: number;
}) {
  return (
    <Card className="flex flex-col flex-1 min-h-0">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <div className="text-sm font-semibold">Your skills</div>
          <CubeIcon className="h-5 w-5" />
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col flex-1 min-h-0">
        <Table>
          <TableBody>
            {(skills || []).map((skill, i) => (
              <TableRow key={skill.skillGroup.name}>
                <TableCell className="text-sm leading-tight">
                  {skill.skillGroup.name}
                </TableCell>
                <TableCell className="text-sm font-semibold leading-tight text-right">
                  {totalPoints
                    ? Math.round((skill.points / totalPoints) * 100)
                    : 0}
                  %
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
